const initialState = {
  cookiesAccepted: false,
  showNotice: null,
}

const cookieReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ACCEPT_COOKIES":
      return {
        ...state,
        cookiesAccepted: action.accepted.cookiesAccepted,
        showNotice: action.accepted.showNotice,
      }

    case "REMOVE_COOKIES":
      return {
        ...state,
        cookiesAccepted: action.accepted.cookiesAccepted,
        showNotice: action.accepted.showNotice,
      }

    case "CHECK_COOKIES":
      return {
        ...state,
        cookiesAccepted: action.accepted.cookiesAccepted,
        showNotice: action.accepted.showNotice,
      }

    default:
      return state
  }
}

export default cookieReducer
