// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-customer-stories-js": () => import("./../../../src/pages/customer-stories.js" /* webpackChunkName: "component---src-pages-customer-stories-js" */),
  "component---src-pages-elearning-authoring-tool-feature-list-js": () => import("./../../../src/pages/elearning-authoring-tool/feature-list.js" /* webpackChunkName: "component---src-pages-elearning-authoring-tool-feature-list-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-showcase-js": () => import("./../../../src/pages/showcase.js" /* webpackChunkName: "component---src-pages-showcase-js" */),
  "component---src-templates-blog-category-js": () => import("./../../../src/templates/blog-category.js" /* webpackChunkName: "component---src-templates-blog-category-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-customer-story-js": () => import("./../../../src/templates/customer-story.js" /* webpackChunkName: "component---src-templates-customer-story-js" */),
  "component---src-templates-event-category-js": () => import("./../../../src/templates/event-category.js" /* webpackChunkName: "component---src-templates-event-category-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-events-list-js": () => import("./../../../src/templates/events-list.js" /* webpackChunkName: "component---src-templates-events-list-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-resource-category-js": () => import("./../../../src/templates/resource-category.js" /* webpackChunkName: "component---src-templates-resource-category-js" */),
  "component---src-templates-resource-js": () => import("./../../../src/templates/resource.js" /* webpackChunkName: "component---src-templates-resource-js" */),
  "component---src-templates-resource-list-js": () => import("./../../../src/templates/resource-list.js" /* webpackChunkName: "component---src-templates-resource-list-js" */),
  "component---src-templates-resource-thank-you-js": () => import("./../../../src/templates/resource-thank-you.js" /* webpackChunkName: "component---src-templates-resource-thank-you-js" */),
  "component---src-templates-showcase-category-js": () => import("./../../../src/templates/showcase-category.js" /* webpackChunkName: "component---src-templates-showcase-category-js" */)
}

